.bg-extra-dark-gray {
    background-color: #1c1c1c;
}

.opacity1 {opacity:.1}
.opacity2 {opacity:.2}
.opacity3 {opacity:.3}
.opacity4 {opacity:.4}
.opacity5 {opacity:.5}
.opacity6 {opacity:.6}
.opacity7 {opacity:.7}
.opacity8 {opacity:.8}
.opacity9 {opacity:.9}

div.overlayed {
	z-index: 0;
	position: relative;
	div, p, span, h1, h2, h3, h4, h5, h6 {
		z-index: 10;
		position: relative;
	}
	div.black-overlay {
		position: absolute;
		background-color: rgba(0,0,0,0.6);
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: 5
	}
	/* opacity */
	.opacity-very-light {position: absolute; height: 100%; width: 100%; opacity: 0.2; top:0; left: 0;}
	.opacity-light {position: absolute; height: 100%; width: 100%; opacity: 0.3; top:0; left: 0;}
	.opacity-extra-medium {position: absolute; height: 100%; width: 100%; opacity: 0.5; top:0; left: 0;}
	.opacity-medium {position: absolute; height: 100%; width: 100%; opacity: 0.75; top:0; left: 0;}
	.opacity-full {position: absolute; height: 100%; width: 100%; opacity: 0.8; top:0; left: 0;}
	.opacity-full-dark {position: absolute; height: 100%; width: 100%; opacity: 0.9; top:0; left: 0;}
}

.not-loaded {
	position: relative;
	#slider-text {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		z-index: 999;
	}
}
.not-loaded {
	filter: blur(20px);
	transform: scale(1);
	-webkit-transition: filter 0.3s linear;
	-moz-transition: filter 0.3s linear;
	-ms-transition: filter 0.3s linear;
	-o-transition: filter 0.3s linear;
	transition: filter 0.3s linear;
	&.loaded {
		filter: blur(0px);
	}
}

/********** CTA **********/

/********** HEADER **********/

/********* FOOTER *********/

/********* HOME **********/
#theme-main-banner {
	.camera_caption {
		.gif-box {
			position: absolute; 
			width: 40%; 
			height: 100%;
			top: 0; 
			right:0; 
			text-align: center;
			padding: 0px 50px 0px 0px;
			img {
				max-width: 300px !important; 
				margin: auto; 
				border-radius: 50%;
			}
			iframe {
				height: 400px !important;
			}
		}
	}
}
.what-we-do .row .col-xs-12 .single-block,
.home-service-section ul li div {
	&.color-one {
		h5 a:hover {
			color: $vert;
		}
	}
	&.color-two {
		h5 a:hover {
			color: $jaune;
		}
	}
	&.color-three {
		h5 a:hover {
			color: $rouge;
		}
	}
	&.color-four {
		h5 a:hover {
			color: $orange;
		}
	}
}
.our-portfolio {
	.item {
		.tran4s {
			a {
				width: 90% !important;
			}
			.client-title {
				font-size: 20px;
				display: block;
				color: black;
				width: 80%;
				margin: 0 auto 10px
			}
			.client-type {
				font-size: 15px;
				display: block;
				color: black;
				width: 70%;
				margin: auto;
			}
		}
	}
	.wrapper .image .opacity a:after, .wrapper .image .opacity a:before {
		content: none;
	}
}

/******** CLIENTS ********/
.gullu-portfolio.portfolio-full-width {
	.grid-item {
		.tran3s {
			a {
				width: 90% !important;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}
			.client-title {
				font-size: 20px;
				display: block;
				color: black;
				width: 80%;
				margin: 0 auto 10px
			}
			.client-type {
				font-size: 15px;
				display: block;
				color: black;
				width: 70%;
				margin: auto;
			}
		}
	}
}
/******** BLOG *********/

/******** ARTICLE ********/

.blog-details .blog-main-post {
	h1 {
		line-height: 40px;
		margin-bottom: 40px;
		font-size: 30px;
		font-weight: 500;
	}
	h2 {
		font-size: 26px;
	    line-height: 30px;
	    font-weight: normal;
	    padding: 0 85px;
	    margin: 80px 0 40px 0;
	    position: relative;
	    &:before {
	    	content: '';
		    width: 40px;
		    height: 2px;
		    background: #000;
		    position: absolute;
		    top: 15px;
		    left: 0;
	    }
	}
	h5 {
		font-size: 20px;
	    line-height: 35px;
	    margin-bottom: 15px;
	    padding-top: 10px;
	    color: #000000;
	}
	p {
		margin-bottom: 20px;
	}
	ul, ol {
		margin-bottom: 20px;
		margin-left: 20px;
		li {
			font-size: 16px;
			color: rgba(0,0,0,0.65);
			padding: 5px 0;
			font-weight: 500;
		}
	}
	ul li {
		list-style-type: disc;
	}
	img {
		max-height: 350px;
		max-width: 800px;
		margin: 20px auto 40px;
	}
	.img-infinite-height {
		img {
			max-height: none !important;
		}
	}
} 
/******** ABOUT **********/

/******** CONTACT ********/

/******** OTHER ********/
.main#merci {
	padding-top: 200px;
	a {
		color: $vert;
	}
}

/******** RESPONSIVE ********/
@media only screen and (max-width: 1199px) {
	#theme-main-banner {
		.camera_caption {
			.gif-box {
				width: 35%;
				padding-right: 20px;
			}
		}
	}
}

@media only screen and (max-width: 991px) {
	#theme-main-banner {
		.camera_caption {
			.gif-box {
				display: none !important;
			}
		}
	}
	.main#merci {
		padding-top: 120px;
	}
}

@media only screen and (max-width: 767px) {
	.blog-details .blog-main-post {
		h2 {
			padding: 0;
			margin-top: 50px;
			&:before {
				width: 0;
			}
		}
		h6 {
			margin-top: 0px;
		}
		img {
			max-width: 100%;
		}
	}
}

@media only screen and (max-width: 479px) {
		
}
@media only screen and (max-width: 400px) {
	
}

@media only screen and (min-width: 1921px) {
	
}

@media only screen and (min-width: 992px) {
	
}

@media only screen and (min-width: 768px) {
	.scroll-top {
		display: none !important;
	}
}	
@media only screen and (min-width: 480px) and (max-width: 768px) {
	
}
@media only screen and (min-width: 768px) and (max-width: 991px) {

}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
	
}

