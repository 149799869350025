$brown: #726758;
$vert: #3daa87;
$rouge: #cf3023;
$jaune: #e99800;
$orange: #df6604;
$gray: #e8e3db;

$hfont: 'Oswald', sans-serif;
$pfont: 'PT Serif', serif;

$logo_height: 120px;