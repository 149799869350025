	/*
  	Flaticon icon font: Flaticon
  	Creation date: 18/09/2017 20:02
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("../fonts/icon/font/Flaticon.eot");
  src: url("../fonts/icon/font/Flaticon.eot?#iefix") format("embedded-opentype"),
       url("../fonts/icon/font/Flaticon.woff") format("woff"),
       url("../fonts/icon/font/Flaticon.ttf") format("truetype"),
       url("../fonts/icon/font/Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("../fonts/icon/font/Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
  font-family: Flaticon;
  font-size: inherit;
  font-style: normal;
  color: inherit;
}

.flaticon-arrows:before { content: "\f100"; }
.flaticon-computer:before { content: "\f101"; }
.flaticon-notepad:before { content: "\f102"; }
.flaticon-diamond:before { content: "\f103"; }
.flaticon-right-arrow:before { content: "\f104"; }
.flaticon-download-arrow:before { content: "\f105"; }
.flaticon-up-arrow:before { content: "\f106"; }
.flaticon-user:before { content: "\f107"; }
.flaticon-round-chart:before { content: "\f108"; }
.flaticon-quote-left:before { content: "\f109"; }
.flaticon-note:before { content: "\f10a"; }
.flaticon-plus:before { content: "\f10b"; }
.flaticon-bar-chart:before { content: "\f10c"; }
.flaticon-check:before { content: "\f10d"; }
.flaticon-drawing:before { content: "\f10e"; }
.flaticon-layers:before { content: "\f10f"; }
.flaticon-smartphone:before { content: "\f110"; }
.flaticon-business:before { content: "\f111"; }
.flaticon-bar-chart2:before { content: "\f112"; }
.flaticon-square:before { content: "\f113"; }
.flaticon-play-button:before { content: "\f114"; }